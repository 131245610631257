<template>
  <new-page>
    <div v-wechat-title="$route.meta.title"></div>
    <header class="header">
      <div class="title">
        <div class="center-title">叶德钦的博客</div>
        <div class="center-subheading"></div>
      </div>
      <!-- 箭头 -->
      <div class="arrows">
        <i class="fa fa-angle-down"></i>
      </div>
    </header>
    <div id="main">
      <!-- 左侧栏 -->
      <div class="left_tab">
        <div class="left_tab_width">
          <!-- 幻灯片 -->
          <el-carousel height="584px" class="carousel">
            <el-carousel-item>
              <img src="../assets/carousel4.jpg" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/carousel1.jpg" alt="" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/carousel3.jpg" alt="" />
            </el-carousel-item>
          </el-carousel>

          <!-- 文章列表 -->
          <NewArticleListInfo ref="ArticleListInfo"></NewArticleListInfo>
        </div>
      </div>
      <!-- 右侧栏 -->
      <div class="right_tab">
        <!-- 时间 -->
        <div class="timeCard">
          <p>{{ time }}</p>
        </div>
        <!-- 博客介绍 -->
        <div class="introduceCard">
          <p class="introduceTitle">叶德钦的博客</p>
          <p class="introduceContent">
            &nbsp;&nbsp;&nbsp;&nbsp;一个喜欢写代码的博主，喜欢去探索未知领域，喜欢学习新内容，喜欢在代码的世界中遨游。目标成为一名优秀的前端开发工程师。
          </p>
        </div>
        <!-- 联系方式 -->
        <div class="smallCard">
          <div class="relativeClass">
            <img src="../assets/qq.png" alt="qq" class="contactIcon" id="qq" />
            <div class="qqPopUp">
              <img src="../assets/qqQRcode.png" alt="" class="QRcodeImHeight" />
            </div>
          </div>
          <img src="../assets/gitee.png" alt="gitee" class="contactIcon" />
          <div class="relativeClass">
            <img
              src="../assets/wechat.png"
              alt="wechat"
              class="contactIcon"
              id="wechat"
            />
            <div class="wechatPopUp">
              <img
                src="../assets/wechatQRcode.png"
                alt=""
                class="QRcodeImHeight"
              />
            </div>
          </div>
        </div>
        <!-- 文章、标签、分类数量展示 -->
        <div class="smallCard">
          <div class="numIcon">
            <span>文章</span>
            <span class="nums">{{ blogNums.articleNums }}</span>
          </div>
          <div class="numIcon">
            <span>分类</span>
            <span class="nums">{{ blogNums.categoryNums }}</span>
          </div>
          <div class="numIcon">
            <span>标签</span>
            <span class="nums">{{ blogNums.tagNums }}</span>
          </div>
        </div>
        <div class="tags_categorys">
          <!-- 文章搜索框 -->
          <div class="smallCard">
            <el-input
              v-model="selectArticleFrom.title"
              placeholder="请输入关键词..."
              @change="onSearch()"
              clearable
            >
              <template #append>
                <span style="cursor: pointer" @click="onSearch()"
                  ><i class="fa fa-search"></i
                ></span>
              </template>
            </el-input>
          </div>
          <!-- 展示所有标签 -->
          <div class="tagCard">
            <span
              v-for="item in allTags"
              :key="item.id"
              class="tagItem"
              @click="onChangeTag(item.id)"
              >{{ item.tag_name }}</span
            >
          </div>
          <!-- 展示所有分类 -->
          <div class="categoryCard">
            <div
              v-for="item in allCategorys"
              :key="item.id"
              class="categroyItem"
              @click="onChangeCategory(item.id)"
            >
              <div class="categoryName">{{ item.category_name }}</div>
              <hr v-if="item.id !== 1" class="categoryHr" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </new-page>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import NewArticleListInfo from '../components/NewArticleListInfo.vue'
import NewPage from '../../src/components/NewPage'
import {
  SELECTBLOGNUMS,
  SELECTALLTAGS,
  SELECTALLCATEGORYS,
  TEST
} from '../api/api.js'
import router from '../router/index'
export default {
  components: {
    NewArticleListInfo,
    NewPage
  },
  setup() {
    const ArticleListInfo = ref(null)
    const subheading = ref()
    onMounted(() => {
      currentTime()
      getBlogNums()
      getAllTags()
      getAllCategorys()
      ArticleListInfo.value.selectArticles()
      TEST().then((res) => {
        subheading.value = res.data.content
        const data = [res.data.content]
        const div = document.querySelector('.center-subheading')
        const arr = []
        for (const item of data) {
          // 打印每一个item => 数组的每一个元素
          console.log(item)
          // 创建p标签
          const p = document.createElement('p')
          // 将p标签插入到container
          for (let i = 0; i < item.length; i++) {
            // 创建span
            const span = document.createElement('span')
            // span的内容等于item的每一个字
            span.innerHTML = item[i]
            // 将span插入到p标签中
            p.append(span)
            // 将span也添加到新数组中
            arr.push(span)
          }
          div.append(p)
        }
        // 延时1毫秒等待上方循环渲染完成
        setTimeout(() => {
          // 遍历arr数组的每一个元素
          arr.forEach((item, index) => {
            // 给每一个元素添加过渡延迟属性
            // 让每一个字都比前一个字延时0.2秒的时间
            item.style.transitionDelay = `${index * 0.2}s`
            // 将透明度设置为不透明
            item.style.opacity = 1
          })
        }, 1)
      })
    })
    const time = ref()
    function currentTime() {
      setInterval(getTime, 500)
    }
    const getTime = () => {
      const hh = new Date().getHours()
      const mf =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      const ss =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()
      time.value = hh + ' : ' + mf + ' : ' + ss
    }
    // 博客数量信息
    const blogNums = reactive({
      articleNums: 0,
      categoryNums: 0,
      tagNums: 0
    })
    // 查询博客信息
    const getBlogNums = () => {
      SELECTBLOGNUMS().then((response) => {
        if (response.code === 0) {
          const { articleNums, categoryNums, tagNums } = response.data
          blogNums.articleNums = articleNums
          blogNums.categoryNums = categoryNums
          blogNums.tagNums = tagNums
        }
      })
    }
    // 标签信息
    const allTags = ref([])
    // 查询所有标签信息
    const getAllTags = () => {
      SELECTALLTAGS().then((response) => {
        if (response.code === 0) {
          allTags.value = response.data
        }
      })
    }
    // 分类信息
    const allCategorys = ref([])
    // 查询所有分类信息
    const getAllCategorys = () => {
      SELECTALLCATEGORYS().then((response) => {
        if (response.code === 0) {
          allCategorys.value = response.data
        }
      })
    }

    // 点击标签
    const onChangeTag = (tag_id) => {
      console.log(tag_id)
      router.push({ name: 'ArticleList_', params: { tag_id: tag_id } })
    }
    // 点击分类
    const onChangeCategory = (category_id) => {
      router.push({ name: 'ArticleList_', params: { category_id: category_id } })
    }

    // 文章搜索表单
    const selectArticleFrom = reactive({
      title: ''
    })

    // 点击搜索
    const onSearch = () => {
      router.push({
        name: 'ArticleList_',
        params: { title: selectArticleFrom.title }
      })
    }

    return {
      ArticleListInfo,
      subheading,
      time,
      blogNums,
      allTags,
      allCategorys,
      onChangeTag,
      onChangeCategory,
      onSearch,
      selectArticleFrom
    }
  }
}
</script>
<style>
:root {
  --subject-color: #0e9fe9;
}
</style>
<style>
.header .title .center-subheading span {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>
<style scoped>
.home {
  background: url('../assets/background.jpg');
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.header {
  height: 100vh;
  position: relative;
}
.header .title {
  position: absolute;
  top: 40%;
  width: 100%;
}
.header .title .center-title {
  text-align: center;
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  color: #fff;
}
.header .title .center-subheading {
  text-align: center;
  width: 100%;
  font-size: 24px;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  color: #fff;
  margin-top: 5px;
  height: 24px;
}

.header .arrows {
  position: absolute;
  bottom: 20px;
  text-align: center;
  color: #eee;
  font-size: 45px;
  width: 100%;
  animation: heart 1.5s infinite forwards linear;
}

@keyframes heart {
  0% {
    bottom: 20px;
    color: #eee;
  }

  50% {
    bottom: 10px;
    color: #eeeeee40;
  }

  100% {
    bottom: 20px;
    color: #eee;
  }
}
#main {
  width: 1200px;
  margin: auto;
}
</style>
<style scoped>

/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#article {
  background-color: #f6f6f6;
  width: 100%;
}

.container {
  height: 100%;
  /* 目的可以水平居中 */
  margin: 0px auto;
  /* 定义元素的最大宽度 */
  max-width: 1200px;
  /* 内容会被修剪，并且其余内容是不可见的 */
  /* overflow: hidden; */
}

#main {
  display: flex;
  /* grid-template-columns: 75% 25%; */
  /* grid-gap: 8px; */
  /* flex-direction: row-reverse; */
}

.right_tab {
  margin-left: 8px;
  width: 290px;
  /* display: none; */
}

.left_tab {
  width: calc(100% - 290px);
}

.left_tab_width {
  width: 876px;
}

/* 左侧——幻灯片 */
.carousel {
  width: 100%;
}

/* 右侧——时间卡片 */
.timeCard {
  width: 100%;
  /* width: 300px; */
  background: url('../assets/time_background.jpg') no-repeat center center/cover;
  height: 192px;
  color: #fff;
  font-size: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

/* 右侧——介绍卡片 */
.introduceCard {
  /* background-color: #ffffff90; */
  background-color: #ffffff;
  height: 160px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.introduceTitle {
  padding: 8px;
  font-size: 18px;
  color: #006699;
}

.introduceContent {
  padding: 8px;
  font-size: 14px;
  line-height: 25px;
}

/* 公共 */
.smallCard {
  margin-top: 16px;
  padding: 16px;
  /* background-color: #ffffff90; */
  background-color: #ffffff;
  /* border-radius: 10px; */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  border-radius: 15px;
}

.smallCard:hover,
.tagCard:hover,
.categoryCard:hover {
  transform: scale(1.02);
}

/* 相对定位 */
.relativeClass {
  position: relative;
}

/* 微信二维码 */
.wechatPopUp {
  padding: 10px;
  background-color: var(--subject-color);
  border-radius: 10px;
  position: absolute;
  z-index: 2;
  right: -10px;
  top: -138px;
  display: none;
}

.wechatPopUp::before {
  content: ' ';
  width: 0px;
  height: 0px;
  border-width: 15px;
  border-style: solid;
  border-top-color: var(--subject-color);
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 118px;
  left: 76px;
}

#wechat:hover + .wechatPopUp {
  display: block;
}

/* qq二维码 */
.qqPopUp {
  padding: 10px;
  background-color: var(--subject-color);
  border-radius: 10px;
  position: absolute;
  z-index: 2;
  right: -65px;
  top: -138px;
  display: none;
}

.qqPopUp::before {
  content: ' ';
  width: 0px;
  height: 0px;
  border-width: 15px;
  border-style: solid;
  border-top-color: var(--subject-color);
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 118px;
  left: 21px;
}

#qq:hover + .qqPopUp {
  display: block;
}

/* 二维码图片高度 */
.QRcodeImHeight {
  height: 100px;
}

/* 联系方式icon */
.contactIcon {
  height: 45px;
  padding: 5px;
}

.contactIcon:hover,
.numIcon:hover {
  background-color: var(--subject-color);
  border-radius: 15px;
}

.tagItem:hover {
  background-color: var(--subject-color);
  border-radius: 15px;
  cursor: pointer;
}

/* 文章、标签、分类数量展示 */
.numIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px;
  font-size: 1.1rem;
}

.numIcon:hover,
.numIcon:hover .nums,
.item:hover {
  color: #fff;
}

.nums {
  color: var(--subject-color);
}

/* 展示标签 */
.tagCard {
  margin-top: 16px;
  padding: 16px;
  /* background-color: #ffffff90; */
  background-color: #ffffff;
  /* border-radius: 10px; */
  display: flex;
  transition: all 0.2s;
  flex-wrap: wrap;
  font-size: 1.1rem;
  border-radius: 15px;
}

.tagItem {
  margin: 4px;
  padding: 8px;
}

/* 分类展示 */
.categoryCard {
  /* background-color: #ffffff90; */
  background-color: #ffffff;
  margin-top: 16px;
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 15px;
}

.categroyItem {
  width: 100%;
  text-align: center;
}

.categoryName {
  padding: 8px 0;
}

.categoryName:hover {
  background-color: #ebeef5;
  border-width: 0 15px;
  border-style: solid;
  border-color: var(--subject-color);
  transition: all 0.5s;
  cursor: pointer;
}

.categoryHr {
  margin: 0px 16px;
  background-color: #f3f4f7;
  border: none;
  height: 0.5px;
}

/* 鱼骨  */
.skeleton {
  background-color: #fff;
  margin-top: 8px;
  padding: 8px;
  width: 100%;
}

/* 文章展示 */
.articleList {
  margin: 8px 0px;
  background-color: #fff;
}

.top {
  content: '';
  height: 0px;
  width: 0px;
  border-width: 20px;
  border-style: solid;
  border-top-color: #2f4858;
  border-right-color: #2f4858;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  right: 0px;
}

.articleUp {
  height: 60px;
  padding: 8px 16px 4px;
}

.articleUp .upIcon {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 16px;
}

.articleTitle {
  padding-bottom: 4px;
  font-weight: bold;
  font-size: 1.1rem;
}

.titleBottom,
.subtitleBottom {
  display: flex;
  justify-content: space-between;
}

.articleTag {
  padding-left: 8px;
}

.titleBottomIcon,
.tag {
  font-size: 12.8px;
  padding-right: 24px;
  color: #999999;
}

.articleHr {
  margin: 4px 16px;
  background-color: var(--subject-color);
  border: none;
  height: 1px;
}

.articleBottom {
  padding: 4px 16px 8px 16px;
  display: flex;
  /* flex-direction: row; */
}

.coverImg {
  height: 120px;
  width: 180px;
  flex-shrink: 0;
  overflow: hidden;
}

.coverPhoto {
  height: 100%;
  width: 100%;
  transition: all 0.5s;
}

.coverPhoto:hover {
  transform: scale(1.3);
}

.articleBottomText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subtitle {
  margin: 0 0 8px 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.subtitleBottomIcon,
.articleCreateTime {
  font-size: 12.8px;
  padding-left: 24px;
  color: #999999;
}

.page {
  display: flex;
  justify-content: center;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

@media screen and (max-width: 1200px) {
  .right_tab {
    display: none;
  }

  .left_tab {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.tags_categorys {
  position: sticky;
  top: 8px;
  margin-bottom: 44px;
}
</style>
<!-- <style src="../css/home.css" scoped></style> -->
